import React from "react"
import { Link } from "gatsby"
const Footer240524 = () => {
  // Images
  const logoMexico = require("../../images/919_mexico_logo.webp")
  const arrowRight = require("../../images/home/arrow-right.svg")
  const auxText = "(Complejo Central Park)"
  return (
    <div className="new-footer">
      <div className="container">
        <img src={logoMexico} alt="logo-mexico" className="new-footer_icon" />
        <div className="row columns mt-30">
          <ul className="list">
            <li>
              <Link to="/employers/">
                <img src={arrowRight} alt="arrow-right" />
                企業のご担当者様
              </Link>
            </li>
            <li>
              <Link to="/contact-us/">
                <img src={arrowRight} alt="arrow-right" />
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link to="/about/">
                <img src={arrowRight} alt="arrow-right" />
                会社概要
              </Link>
            </li>

            <li>
              <Link to="/termsofuse/">
                <img src={arrowRight} alt="arrow-right" />
                利用規約・個人情報保護方針
              </Link>
            </li>
          </ul>
          <div className="sec2-footer">
            <p className="office-name">Aguascalientes Office (Head office)</p>
            <div className="division">
              <div className="title">
                <p>住所</p>
                <p>電話番号</p>
              </div>
              <p className="address">
                C.C. Punto 45, Boulevard A Zacatecas 845, <br />
                Oficina 510, Desarrollo Especial Bulevar a Zacatecas, <br />
                C.P.20116 Aguascalientes <br />
                +52-449-153-1400
              </p>
            </div>
          </div>
          <div className="sec3-footer">
            <p className="office-name">Querétaro Office</p>
            <div className="division">
              <div className="title">
                <p>住所</p>
                <p>電話番号</p>
              </div>
              <p className="address">
                Av. Armando Birlain 2001 Corporativo 1 Piso 15A, <br />
                Colonia Centro Sur {auxText}, <br /> Santiago de Querétaro, Qro.
                C.P 76090 <br />
                +52-449-111-8229
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="container">
          Copyright © QUICK GLOBAL MEXICO, S.A. DE C.V. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer240524
