import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import TimeMexJp from "../TimeMexJp"
import SearchHeader from "../SearchHeader"

const Header170424 = () => {
  // Images header Mexico
  const logoMexico = require("../../images/919_mexico_logo.webp")
  const arrowRight = require("../../images/home/arrow-right.svg")
  const dot = require("../../images/new-header/dot.svg")
  const usFlag = require("../../images/new-header/usa-flag.svg")
  const chinaFlag = require("../../images/new-header/china-flag.svg")

  //Menu state
  const [hamburgerMenuState, setHamburgerMenuState] = useState("")

  //Click outside menu
  const menuRef = useRef(null)
  const hamburgerButton = useRef(null)

  const handleClickOutsideMenu = event => {
    if (
      !hamburgerButton.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setHamburgerMenuState("")
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu)
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu)
    }
  }, [])

  return (
    <>
      <div className={"new-header"}>
        <Link to="/" className="mexico-logo">
          <img src={logoMexico} alt="919 Mexico" width="380" height="40" />
          <p>メキシコ求人情報とメキシコ就職・転職サポート</p>
        </Link>
        <div className="header-right">
          <Link to="/jobs-listing" className="no-show-mobile">
            <button className="header-right_b2">求人情報</button>
          </Link>
          <div className="search-box no-show-mobile">
            <SearchHeader />
          </div>

          <div
            class={"hamburger-menu " + hamburgerMenuState}
            onClick={() => {
              hamburgerMenuState === ""
                ? setHamburgerMenuState("active")
                : setHamburgerMenuState("")
            }}
            role="button"
            tabIndex="0"
            onKeyDown={e => {
              if (e.key === "Enter" || e.key === " ") {
                hamburgerMenuState === ""
                  ? setHamburgerMenuState("active")
                  : setHamburgerMenuState("")
              }
            }}
            ref={hamburgerButton}
          >
            <svg
              id="hamburger"
              width="60"
              height="40"
              className="no-show-mobile"
            >
              <g
                stroke="#000"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path id="top-line" d="M10,10 L45,10 Z"></path>
                <path id="middle-line" d="M10,20 L45,20 Z"></path>
                <path id="bottom-line" d="M10,30 L45,30 Z"></path>
              </g>
            </svg>
            <svg id="hamburger" width="40" height="30" className="show-mobile">
              <g
                stroke="#000"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path id="top-line" d="M10,10 L35,10 Z"></path>
                <path id="middle-line" d="M10,17 L35,17 Z"></path>
                <path id="bottom-line" d="M10,24 L35,24 Z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className={"header-dropdown " + hamburgerMenuState} ref={menuRef}>
        <div className="header-dropdown_container">
          <div className="hmenu_sec1">
            <p className="hmenu_title">
              <Link to="/jobseekers" onClick={() => setHamburgerMenuState("")}>
                <img src={arrowRight} alt="arrow-right" />
                お仕事をお探しの方へ
              </Link>
            </p>
            <div className="hmenu_sec1_2col">
              <div
                onClick={() => setHamburgerMenuState("")}
                role="button"
                tabIndex="0"
                onKeyDown={e => {
                  if (e.key === "Enter" || e.key === " ") {
                    setHamburgerMenuState("")
                  }
                }}
              >
                <ul className="hmenu_sec1_2col_first">
                  <li>
                    <Link to="/process">
                      <img src={dot} alt="dot" />
                      お仕事紹介の流れ
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-mexico">
                      <img src={dot} alt="dot" />
                      よくある質問
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/オススメのメキシコ求人">
                      <img src={dot} alt="dot" />
                      今週のピックアップ求人
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs-listing">
                      <img src={dot} alt="dot" />
                      求人情報 一覧
                    </Link>
                  </li>
                  <li>
                    <Link to="/job-form">
                      <img src={dot} alt="dot" />
                      無料相談フォーム
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                onClick={() => setHamburgerMenuState("")}
                role="button"
                tabIndex="0"
                onKeyDown={e => {
                  if (e.key === "Enter" || e.key === " ") {
                    setHamburgerMenuState("")
                  }
                }}
              >
                <ul className="hmenu_sec1_2col_second">
                  <li>
                    <img src={dot} alt="dot" />
                    人気記事TOP5
                  </li>
                  <li>
                    <Link to="/info/12-article-for-work-in-mexico/">
                      1位 メキシコで働くための12ヵ条
                    </Link>
                  </li>
                  <li>
                    <Link to="/info/different-points-by-occupation/">
                      2位 職種別、メキシコと日本の違い
                    </Link>
                  </li>
                  <li>
                    <Link to="/info/employee-benefits/">
                      3位 メキシコの福利厚生
                    </Link>
                  </li>
                  <li>
                    <Link to="/info/online-interview/">
                      4位 オンライン面接を完全攻略！
                    </Link>
                  </li>
                  <li>
                    <Link to="/info/how-to-write-a-cv-in-mexico/">
                      5位 スペイン語での履歴書の書き方
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="hmenu_sec2">
            <p className="hmenu_title">
              <img src={arrowRight} alt="arrow-right" />
              メキシコ就職・生活情報
            </p>
            <div
              onClick={() => setHamburgerMenuState("")}
              role="button"
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === "Enter" || e.key === " ") {
                  setHamburgerMenuState("")
                }
              }}
            >
              <ul className="hmenu_sec1_2col_first">
                <li>
                  <Link to="/info-cat/mexico-jobs">
                    <img src={dot} alt="dot" />
                    メキシコで働く
                  </Link>
                </li>
                <li>
                  <Link to="/info-cat/spanish">
                    <img src={dot} alt="dot" />
                    スペイン語を学ぶ
                  </Link>
                </li>
                <li>
                  <Link to="/info-cat/mexico-life">
                    <img src={dot} alt="dot" />
                    メキシコで暮らす
                  </Link>
                </li>
                <li>
                  <Link to="/info-cat/company-information">
                    <img src={dot} alt="dot" />
                    クイックからのお知らせ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="hmenu_sec3">
            <div
              onClick={() => setHamburgerMenuState("")}
              role="button"
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === "Enter" || e.key === " ") {
                  setHamburgerMenuState("")
                }
              }}
            >
              <ul>
                <li>
                  <Link to="/employers">企業のご担当者様へ</Link>
                </li>
                <li>
                  <Link to="/about">会社概要</Link>
                </li>
                <li>
                  <Link to="/contact-us">お問い合わせ</Link>
                </li>
              </ul>
              <div className="hmenu_sec3_final">
                <div className="hmenu_sec3_social-flag">
                  <div className="container-social">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/quickglobalmexico/"
                      aria-label="Instagram"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/quickmexico"
                      aria-label="Twitter"
                    >
                      <FaXTwitter />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/919mexico/"
                      aria-label="Facebook"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/919mexico/"
                      aria-label="Linkedin"
                    >
                      <FaLinkedinIn />
                    </a>
                  </div>
                  <div className="container-flag">
                    <a
                      href="https://919mexico-en.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={usFlag} alt="us-flag" />
                    </a>
                    <a
                      href="https://919mexico-ch.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={chinaFlag} alt="china-flag" />
                    </a>
                  </div>
                </div>
                <TimeMexJp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header170424
